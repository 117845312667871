$default-color: #000;
$white:#fff;
$wcagBg:$white;
$menuText:$white;
$activeLink:$white;
$textColor:$white;
$activeButton:$default-color;
$activeButton2:$default-color;
$colorTitle:$white;
$colorNews:$white;
$contactHeader:$white;
$contactText:$white;
$footerBg:$default-color;
$footerText:$white;
$pageBg:$default-color;
$pageBgHeader:$default-color;
$pageHeader:$white;
$pagination:$pageBgHeader;
$menuBorder:$white;
$galleryText:$white;
$searchButton:$default-color;
$menuBackground:$default-color;
$recomBg:$default-color;
$recomTxt:$white;
$menuButton:$white;
$buttonBorder:$white;
$menuActive:#000;
$whiteForm:#fff;
$buttonColor:#000;
$backgroundColor:#fff;
$lang:#000 !important;
$langActive:#000 !important;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;
//top section home
$topSection:$default-color;
//news widget background
$newsWidget:$default-color;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1025px !default;
$screen-lg:                  1200px !default;


.input--search{
	border-radius:0 !important;
	border-color:$default-color !important;
	color:$default-color !important;

	&::placeholder{
		color:$default-color !important;
	}
}

.contact{
	background:#000 !important;
	&__block{
		background:#000 !important;
	}
}


.section,.recommended,.contact,.page__block,.page__content{
	border-top:3px solid $white;
}



.listGallery__box{
border:3px solid $white;
	&-text{
	background:$default-color !important;
}
}

.menu__li.active.open .dropdown-menu .menu__li.active .menu__a{
	background:#000;
	text-decoration:underline;
}

.dropdown-menu{
	border:3px solid $white !important;
}

html,body{
	a,span{
		color:$white !important;
	}
}


.menu .menu__li .menu__a:hover, .menu .menu__li .menu__a:focus{
border-color: $default-color !important;
text-decoration:underline;
}

.menu .menu__li.active .menu__a{
	border-color: $default-color !important;
	text-decoration:underline;
}

.menu__li.active.open .dropdown-menu .menu__li .menu__a{
	text-decoration:none;
}
.mobileMenu .dropdown-menu{
	border:0 !important;
}

.mobileMenu .menu__li.active .menu__a{
	text-decoration:underline !important;
}

.menu__li.active.open .dropdown-menu .menu__li .menu__a{
	text-decoration:none !important;
}
.menu__li.active.open .dropdown-menu .menu__li.active .menu__a{
	text-decoration:underline !important;
}

.dropdown-menu > .menu__li > .menu__a{
	text-decoration:none !important;	
}

.menu__li.active.show .dropdown-menu .menu__li.active .menu__a{
		text-decoration:underline !important;			
}
